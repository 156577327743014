@import "@/assets/scss/main.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.4;
    filter: alpha(opacity=50);
    background-color: gray;
}

.answers {
    overflow-y: scroll;
    max-height: 450px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    margin-left: 20px;
}


.answer {
    height: auto;
    border: none;
    margin-bottom: 12px
}

.answer-title {
    display: grid;
    grid-template-columns: 24px auto;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
}

.answer-body-line {
    display: grid;
    grid-template-columns: 24px auto;
    margin-left: 24px;
    align-items: center;
}

.category-answers {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 20% 80%;
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
    max-height: 500px;
}

.wrapper {
    margin-bottom: 1rem;
}

section#category {
    display: flex;
    width: 100%;
    flex-direction: column;

    .category {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 15% 70% 5% 2% 5%;
        grid-column-gap: 1.5rem;
        height: 5rem;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

    }
}


.collbtn {
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon:not(.not-rotate) {
        transform: rotate(180deg);
    }
}
