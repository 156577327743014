@import "@/assets/scss/main.scss";

#bar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    justify-content: space-between;
    gap: 0.4%;
    flex: initial;
    background-color: $elsi-color-bar-bg;
    height: 20px;
    clip-path: inset(0 0 0 0 round 10px);

    .segment {
        flex: 1;
        height: 20px;
        background-color: $elsi-color-bar-bg;
    }

    .segment_1 {
        background-color: $elsi-color-blue;
    }

    .segment_2 {
        background-color: #A1DBED;
    }

}
